import React, { Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/components.css";
import Loading from "./components/Loading";

const Landing = React.lazy(() => import("./pages/Landing"));
const Payment = React.lazy(() => import("./pages/Payment"));
const SuccessPage = React.lazy(() => import("./pages/PaymentSuccess"));
const Customers = React.lazy(() => import("./pages/admin/Customers"));
const Login = React.lazy(() => import("./pages/admin/Login"));

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <div className="app">
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Landing lang="en" />
            </Suspense>
          }
        />
        <Route
          path="/fr"
          element={
            <Suspense fallback={<Loading />}>
              <Landing lang="fr" />
            </Suspense>
          }
        />
        <Route
          path="/apply"
          element={
            <Suspense fallback={<Loading />}>
              <Payment />
            </Suspense>
          }
        />
        <Route
          path="/payment/success"
          element={
            <Suspense fallback={<Loading />}>
              <SuccessPage />
            </Suspense>
          }
        />

        <Route
          path="login"
          element={
            <Suspense fallback={<Loading />}>
              <Login setLoggedIn={setLoggedIn} />
            </Suspense>
          }
        />
        {loggedIn ? (
          <Route
            path="/admin/customers"
            element={
              <Suspense fallback={<Loading />}>
                <Customers setLoggedIn={setLoggedIn} />
              </Suspense>
            }
          />
        ) : (
          ""
        )}
      </Routes>
    </div>
  );
}

export default App;
